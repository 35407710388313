<template>
    <BaseSection
        section-width="content"
        class="section--brandstofmonitor"
    >
        <ClientOnly>
            <BrandstofMonitor />
        </ClientOnly>
    </BaseSection>
</template>
